import React from 'react';
import {
    Modal, Backdrop, Fade, Grid,
    FormControl,
    InputLabel, Input, Button
} from '@material-ui/core';
import './ModalForm.css';

function ModalFormConsulting(props) {
    //Form pops up when you click LEARN MORE under consulting on the Services page
    return (
        <div>
            <Modal
                aria-labelledby={props.modalTitle}
                aria-describedby={props.modalDescription}
                className='modal-form'
                open={props.open}
                onClose={props.handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}>
                <Fade in={props.open}>
                    <form accept-charset="UTF-8" action="https://nv695.infusionsoft.com/app/form/process/88d69c2196fbff8bf16eb014abbf44a0" className="infusion-form" id="inf_form_88d69c2196fbff8bf16eb014abbf44a0" method="POST">
                        <input name="inf_form_xid" type="hidden" value="88d69c2196fbff8bf16eb014abbf44a0" />
                        <input name="inf_form_name" type="hidden" value="Consulting Form" />
                        <input name="infusionsoft_version" type="hidden" value="1.70.0.258945" />
                        <h2 className='modal-title'>Consulting Interest</h2>
                        <Grid container
                            spacing={3}
                            direction="column"
                            justify="center"
                            alignItems="center"
                            className='modal-grid'>
                            <Grid item sm={12}>
                                <FormControl>
                                    <InputLabel htmlFor="inf_field_FirstName">First Name *</InputLabel>
                                    <Input className='formControl infusion-field-input' id="inf_field_FirstName" name="inf_field_FirstName" type="text" />
                                </FormControl>
                            </Grid>
                            <Grid item sm={12}>
                                <FormControl>
                                    <InputLabel htmlFor="inf_field_LastName">Last Name *</InputLabel>
                                    <Input className='formControl infusion-field-input' id="inf_field_LastName" name="inf_field_LastName" type="text" />
                                </FormControl>
                            </Grid>
                            <Grid item sm={12}>
                                <FormControl>
                                    <InputLabel htmlFor="inf_field_Email">Email *</InputLabel>
                                    <Input className='formControl infusion-field-input' id="inf_field_Email" name="inf_field_Email" type="text" />
                                </FormControl>
                            </Grid>
                            {/* <Grid item sm={12}>
                                <FormControl>
                                    <InputLabel id='interest-label' for="inf_custom_Interest">Interest *</InputLabel>
                                    <Select
                                        labelId='interest-label'
                                        id="inf_custom_Interest"
                                        className='select-control formControl'
                                        name='inf_custom_Interest'
                                        value={interest}
                                        onChange={handleSelectChange}>
                                        <MenuItem value='development'>Development</MenuItem>
                                        <MenuItem value='consulting'>Consulting</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid> */}
                        </Grid>
                        <br />
                        <div class="infusion-submit" style={{ textAlign: 'center' }}>
                            <Button type="submit" variant='contained' className='submit-btn'>Submit</Button>
                        </div>
                        <br />
                    </form>
                </Fade>
            </Modal>
            <script type="text/javascript" src="https://nv695.infusionsoft.app/app/webTracking/getTrackingCode"></script>
            <script type="text/javascript" src="https://nv695.infusionsoft.com/app/timezone/timezoneInputJs?xid=88d69c2196fbff8bf16eb014abbf44a0"></script>
        </div>
    )
}

export default ModalFormConsulting;

import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import CourseTable from '../../Constants/CourseTable';
import ModalOnline from '../../Constants/Modal/ModalOnline';
import './OnlineResources.css';

function OnlineResources(props) {
    const { isAvail, data, courseType } = props;
    const onlineData = data;

    //prodData.project will passed as prop
    const [prodData, setProdData] = useState({
        "projectMgt": [],
        "querying": [],
        "visualTools": []
    });
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        let today = new Date();
        setProdData({
            "projectMgt": onlineData.filter(c => (c.acf.category === "proj-mgt") && (today < new Date(`${c.acf.class_date}`))),
            "querying": onlineData.filter(c => (c.acf.category === "querying") && (today < new Date(`${c.acf.class_date}`))),
            "visualTools": onlineData.filter(c => (c.acf.category === "visual-tools") && (today < new Date(`${c.acf.class_date}`)))
        });
    }, [onlineData])


    let handleModalOpen = () => {
        setModalOpen(true);
    };

    let handleModalClose = () => {
        setModalOpen(false);
    };

    return (
        <>
            <Grid className='online-grid'>
                <Grid md={12} item className=''>
                    {isAvail ?
                        <>
                            {/* <h2 className='course-title'>Project Management</h2>
                            {
                                prodData.projectMgt.length ?
                                    <>
                                        <CourseTable data={prodData.projectMgt} handleModalOpen={handleModalOpen} isOnline />
                                        <ModalOnline open={modalOpen} setOpen={setModalOpen}
                                            handleClose={handleModalClose} classData={prodData.projectMgt}
                                            modalTitle='registration-form' modalDescription='This is a pop up form for clients to register for a course'
                                            category={"proj-mgt"} courseType={courseType} />
                                    </>
                                    : <h4 className='no-avail'>No Available Courses</h4>
                            }
                            < br /> */}
                            <h2 className='course-title'>Data Analytics & Querying</h2>
                            {
                                prodData.querying.length ?
                                    <>
                                        <CourseTable data={prodData.querying} handleModalOpen={handleModalOpen} isOnline />
                                        <ModalOnline open={modalOpen} setOpen={setModalOpen}
                                            handleClose={handleModalClose} classData={prodData.querying}
                                            modalTitle='registration-form' modalDescription='This is a pop up form for clients to register for a course'
                                            category={'data-analytics-and-querying'} courseType={courseType} />
                                    </>
                                    : <h4 className='no-avail'>No Available Courses</h4>
                            }
                            < br />
                            <h2 className='course-title'>Visual Analytic Tools</h2>
                            {
                                prodData.visualTools.length ?
                                    <>
                                        <CourseTable data={prodData.visualTools} handleModalOpen={handleModalOpen} isOnline />
                                        <ModalOnline open={modalOpen} setOpen={setModalOpen}
                                            handleClose={handleModalClose} classData={prodData.visualTools}
                                            modalTitle='registration-form' modalDescription='This is a pop up form for clients to register for a course'
                                            category={'visual-analytic-tools'} courseType={courseType} />
                                            
                                    </>
                                    : <h4 className='no-avail'>No Available Courses</h4>
                            }
                        </> :
                        <div className='coming'>
                            <h2>Coming Soon</h2>
                        </div>
                    }
                </Grid>
            </Grid>
        </>
    )
}

export default OnlineResources


import React from 'react';
import { Grid } from '@material-ui/core';
import '../Views/Home/Home.css';

function AboutSnippet(props) {
    return (
        <>
            <section className='about-area'>
                <Grid container
                    spacing={3}
                    direction="row"
                    justify="left"
                    alignItems="left"
                    className='about-grid'>
                    <Grid item md={4} className='aboutPic-item'>
                        <img src={props.aboutPic} width="100%" height="auto" alt="Woman leaning against the wall with laptop in hand" />
                    </Grid>
                    <Grid item md={8}>
                        <p>
                            As more data across the globe is required to be stored and provide analytics from disparate data sources or big data set sources for organizations. Business users must make sense of the puzzling array of information. Data visualization techniques are needed.
                        </p>
                        <p>
                            Data visualization is an integral part of any data analytic efforts, allowing users to immediately spot trends, track goal achievement, easily identify outliers and compare the performance of different categories, forecasting, predictive analytics, etc.
                        </p>
                        <p>
                            We provide tailored solutions that assist and help organizations solve this challenge providing consulting, mentoring or development solutions utilizing new advanced data visualization tools along with 15 years real world experience. No matter the skillset, we tailor visualization solutions that help answer the business questions and visualize the KPI’s & metrics to individual business needs.
                        </p>
                    </Grid>
                </Grid>
            </section>
        </>
    )
}

export default AboutSnippet

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    AppBar, Toolbar, Typography,
    IconButton, Grid, Button,
    MenuItem, Grow, Popper,
    ClickAwayListener, MenuList,
    Divider
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Menu, ArrowDropDown } from '@material-ui/icons';
import fb from '../../media/facebook.png';
import ig from '../../media/instagram.png';
import linkedin from '../../media/linkedin.png';
import './Navigation.css';

const useStyles = makeStyles(theme => ({
    root: {
        background: '#05647a',
        borderRadius: '5px',
        margin: 0,
        '& .MuiList-root': {
            background: '#05647a',
        }
    },
    item: {
        background: 'transparent',
        '&:hover': {
            background: '#10375d'
        }
    },
    menuIcon: {
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
        // float: 'right'
    },
    toolbar: theme.mixins.toolbar,
    navList: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    socials: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    logo: {
        [theme.breakpoints.down('lg')]: {
            float: 'left',
        },
    }
}));

function Navigation(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const dropDownRef = React.useRef(null);
    const menuIconRef = React.useRef(null);

    const handleDropdown = () => {
        setOpen((prev) => !prev);
    };
    const handleMenuIcon = () => {
        setMenuOpen((prev) => !prev);
    };

    const handleClose = (event) => {
        if (dropDownRef.current && dropDownRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };
    const handleMenuClose = (event) => {
        if (menuIconRef.current && menuIconRef.current.contains(event.target)) {
            return;
        }

        setMenuOpen(false);
    };

    //return focus to button after transition
    const prev = React.useRef(open);
    useEffect(() => {
        if (prev.current === true && open === false) {
            dropDownRef.current.focus();
        }

        prev.current = open;
    }, [open]);

    const topicsDropDown = (
        <>
            <Button aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                ref={dropDownRef}
                onClick={handleDropdown}
                className='navlink navlink-button'
            >
                Topics <ArrowDropDown className="dropdown-arrow" />
            </Button>
            <Popper open={open}
                anchorEl={dropDownRef.current}
                role={undefined}
                transition
                disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                                className={classes.root}
                                autoFocusItem={open}
                                id="menu-list-grow"
                            >
                                {/* <MenuItem className={classes.item} onClick={handleClose}><Link to='/services/development/project-management' className='navlink-Link drop-link'>Project Management</Link></MenuItem> */}
                                <MenuItem className={classes.item} onClick={handleClose}><Link to='/services/development/data-analytics-and-querying' className='navlink-Link'>Data Analytics & Querying</Link></MenuItem>
                                <MenuItem className={classes.item} onClick={handleClose}><Link to='/services/development/visual-analytic-tools' className='navlink-Link'>Visual Analytic Tools</Link></MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Grow>
                )}
            </Popper>
        </>
    )

    return (
        <>
            <AppBar position='absolute' className='navbar'>
                <Toolbar className={`links`}>
                    <Grid justify={"space-between"} container>
                        <Grid xs={2} item className={classes.logo}>
                            <></>
                        </Grid>
                        <Grid xs={8} item className={classes.navList}>
                            <Grid container justify={"center"}>
                                <Typography variant='h6' className='navlink'>
                                    <Link to='/' className='navlink-Link'>Home</Link>
                                </Typography>
                                <Typography variant='h6' className='navlink'>
                                    {topicsDropDown}
                                </Typography>
                                <Typography variant='h6' className='navlink'>
                                    <Link to='/virtual-seminars' className='navlink-Link'>Virtual Seminars</Link>
                                </Typography>
                                {/* <Typography variant='h6' className='navlink'>
                                    <Link to='/webinars' className='navlink-Link'>Webinars</Link>
                                </Typography> */}
                                <Typography variant='h6' className='navlink'>
                                    <Link to='/about' className='navlink-Link'>About</Link>
                                </Typography>
                                <Typography variant='h6' className='navlink'>
                                    <a href='./about#contactform' className='navlink-Link'>Contact Us</a>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={2} className={`${classes.socials} social-grid`}>
                            <Grid container justify={"flex-end"}>
                                <div className='social-media-links'>
                                    <IconButton edge='end' className='icon'>
                                        <a  rel="noopener noreferrer" href="https://www.linkedin.com/in/tarangrace/" target="_blank"><img src={linkedin} width='16px' height='auto' alt="LinkedIn Logo"/></a>
                                    </IconButton>
                                    <IconButton edge='end' className='icon'>
                                        <img src={fb} width='16px' height='auto' alt="Facebook Logo" />
                                    </IconButton>
                                    <IconButton edge='end' className='icon'>
                                        <img src={ig} width='16px' height='auto' alt="Instagram Logo"/>
                                    </IconButton>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} className='menuIcon'>
                            <IconButton
                                color="inherit"
                                aria-controls={menuOpen ? 'hidden-menu-list-grow' : undefined}
                                aria-haspopup="true"
                                ref={menuIconRef}
                                onClick={handleMenuIcon}
                                className={classes.menuIcon}>
                                <Menu />
                            </IconButton>
                            <Popper open={menuOpen}
                                anchorEl={menuIconRef.current}
                                role={undefined}
                                transition
                                disablePortal>
                                {({ TransitionProps, placement }) => (
                                    <Grow {...TransitionProps}
                                        style={{ transformOrigin: placement === 'right' ? 'right top' : 'right bottom' }}>
                                        <ClickAwayListener onClickAway={handleMenuClose}>
                                            <MenuList
                                                className={classes.root}
                                                autoFocusItem={menuOpen}
                                                id="hidden-menu-list-grow"
                                            >
                                                <MenuItem className={classes.item} onClick={handleMenuClose}><Link to='/' className='navlink-Link'>Home</Link></MenuItem>
                                                <Divider />
                                                {/* <MenuItem className={classes.item} onClick={handleMenuClose}><Link to='/services/development/project-management' className='navlink-Link drop-link'>Project Management</Link></MenuItem> */}
                                                <MenuItem className={classes.item} onClick={handleMenuClose}><Link to='/services/development/data-analytics-and-querying' className='navlink-Link'>Data Analytics & Querying</Link></MenuItem>
                                                <MenuItem className={classes.item} onClick={handleMenuClose}><Link to='/services/development/visual-analytic-tools' className='navlink-Link'>Visual Analytic Tools</Link></MenuItem>
                                                <Divider />
                                                <MenuItem className={classes.item} onClick={handleMenuClose}><Link to='/virtual-seminars' className='navlink-Link'>Virtual Seminars</Link></MenuItem>
                                                <MenuItem className={classes.item} onClick={handleMenuClose}><Link to='/webinars' className='navlink-Link'>Webinars</Link></MenuItem>
                                                <MenuItem className={classes.item} onClick={handleMenuClose}><Link to='/about' className='navlink-Link'>About</Link></MenuItem>
                                                <MenuItem className={classes.item} onClick={handleMenuClose}><a href='https://trgraceconsulting.com/about#contactform' className='navlink-Link'>Contact Us</a></MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Grow>
                                )}
                            </Popper>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </>
    )
}

export default Navigation;
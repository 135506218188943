import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import logo from '../media/logo.png'
import fb from '../media/facebook.png';
import ig from '../media/instagram.png';
import linkedin from '../media/linkedin.png';

function ImportantLinks() {
    return (
        <>
            <section className='important-links'>
                <div className="wrapper">
                    <Grid justify={"space-between"} container>
                        <Grid xs={2} item>
                            <br />
                            <i className='copyright'>trgraceConsulting ©</i>
                        </Grid>
                        {/* <Grid xs={2} item className='grid-logo'>
                            <Grid justify={'center'} container>

                            </Grid>
                        </Grid> */}
                        <Grid xs={3} item>
                            <Grid container justify={"center"}>
                                <ul>
                                    <li><a href='./virtual-seminars' className='navlink-Link'>Virtual Seminars</a></li>
                                    {/* <li><Link to='/about' className='navlink-Link'>Webinars</Link></li> */}
                                    {/* <li><Link to='/services/development/project-management' className='navlink-Link'>Project Management</Link></li> */}
                                    <li><Link to='/services/development/data-analytics-and-querying' className='navlink-Link'>Data Analytics and Querying</Link></li>
                                    <li><Link to='/services/development/visual-analytic-tools' className='navlink-Link'>Visual Analytic Tools</Link></li>
                                </ul>
                            </Grid>
                        </Grid>
                        <Grid xs={3} item>
                            <Grid container justify={"center"}>
                                <ul>
                                    <li><a href='./about#contactform' className='navlink-Link'>Contact Us</a></li>
                                    <li><Link to='/about' className='navlink-Link'>About Us</Link></li>
                                    <li><Link to='/services' className='navlink-Link'>Learn More</Link></li>
                                </ul>

                            </Grid>
                        </Grid>
                        <Grid xs={2} item>
                            <div className={`social-media-bottom`} style={{ float: 'right', marginRight: '1em' }}>
                                <div className='social-media-links'>
                                    <IconButton edge='end' className='icon'>
                                        <a href="https://www.linkedin.com/in/tarangrace/" rel="noopener noreferrer" target="_blank">
                                            <img src={linkedin} alt="LinkedIn Logo" width='16px' height='auto' /></a>
                                    </IconButton>
                                    <IconButton edge='end' className='icon'>
                                        <img src={fb} width='16px' height='auto' alt='Facebook Logo' />
                                    </IconButton>
                                    <IconButton edge='end' className='icon'>
                                        <img src={ig} width='16px' height='auto' alt="Instagram Logo" />
                                    </IconButton>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>
        </>
    )
}

export default ImportantLinks

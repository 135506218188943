import React, { useState } from 'react';
import jQuery from 'jquery';
import {
    Grid, FormControl, InputLabel,
    Input, Button, Checkbox, FormControlLabel,
    FormGroup, Select, MenuItem
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import './About.css';

function About(props) {
    //data
    const { aboutPic } = props;
    const [state, setState] = useState({
        subscription: false,
        inf_custom_ClassType: '',
        inf_custom_Training: ''
    });

    //event handlers
    let handleCheckboxChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked
        });
        console.log(state);
    };

    let handleSelectChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        })
        console.log(state);
    };

    let submitWebForm = () => {
        var form = document.forms[0];
        var resolution = document.createElement('input');
        resolution.setAttribute('id', 'screenResolution');
        resolution.setAttribute('type', 'hidden');
        resolution.setAttribute('name', 'screenResolution');
        var resolutionString = window.screen.width + 'x' + window.screen.height;
        resolution.setAttribute('value', resolutionString);
        form.appendChild(resolution);
        var pluginString = '';

        if (window.ActiveXObject) {
            var activeXNames = {
                'AcroPDF.PDF': 'Adobe Reader',
                'ShockwaveFlash.ShockwaveFlash': 'Flash',
                'QuickTime.QuickTime': 'Quick Time',
                'SWCtl': 'Shockwave',
                'WMPLayer.OCX': 'Windows Media Player',
                'AgControl.AgControl': 'Silverlight'
            };
            var plugin = null;
            for (var activeKey in activeXNames) {
                try {
                    plugin = null;
                    plugin = new window.ActiveXObject(activeKey);
                } catch (e) {
                    // do nothing, the plugin is not installed
                }
                pluginString += activeXNames[activeKey] + ',';
            }
            var realPlayerNames = ['rmockx.RealPlayer G2 Control',
                'rmocx.RealPlayer G2 Control.1',
                'RealPlayer.RealPlayer(tm) ActiveX Control (32-bit)',
                'RealVideo.RealVideo(tm) ActiveX Control (32-bit)',
                'RealPlayer'];
            for (var index = 0; index < realPlayerNames.length; index++) {
                try {
                    plugin = new window.ActiveXObject(realPlayerNames[index]);
                } catch (e) {
                    continue;
                }
                if (plugin) {
                    break;
                }
            }
            if (plugin) {
                pluginString += 'RealPlayer,';
            }
        } else {
            for (var i = 0; i < navigator.plugins.length; i++) {
                pluginString += navigator.plugins[i].name + ',';
            }
        }
        pluginString = pluginString.substring(0, pluginString.lastIndexOf(','));
        var plugins = document.createElement('input');
        plugins.setAttribute('id', 'pluginList');
        plugins.setAttribute('type', 'hidden');
        plugins.setAttribute('name', 'pluginList');
        plugins.setAttribute('value', pluginString);
        form.appendChild(plugins);
        var java = navigator.javaEnabled();
        var javaEnabled = document.createElement('input');
        javaEnabled.setAttribute('id', 'javaEnabled');
        javaEnabled.setAttribute('type', 'hidden');
        javaEnabled.setAttribute('name', 'javaEnabled');
        javaEnabled.setAttribute('value', java);
        form.appendChild(javaEnabled);
        jQuery('.infusion-submit button').attr('disabled', true);
    }

    return (
        <>
            <h1 style={{ textAlign: 'center', marginTop: '7%' }}>About Us</h1>
            <section className='bio-section'>
                <Grid container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    className='bio-grid'>
                    <Grid item className="col" md={6}>
                        <div className='grid-item'>
                            <div className='item-content'>
                                <h3 className='name'>Taran R. Grace</h3>
                                <p>
                                    <span id="bigT">T</span>aran’s background includes twelve years of one-on-one and group training in a corporate setting. She has also participated in the instructional design of corporate training manuals as well as other presentation tools.
                                    Taran has been a contract Master Instructor in the Oil and Gas  industry for more than 10 years, specializing in Project Management, SQL Server  database development, analysis and reporting services.
                                    <br />
                                    <br />
                                    Taran has also conducted custom training sessions and seminars at a variety of companies and Universities throughout the US.
                                    <br />
                                    <br />
                                    Taran has both practical and professional experience on top of her instructional proficiencies.  She has consulted on a variety of projects.
                                    <br />
                                    <br />
                                    Tarans’ greatest reward comes directly from her students that not only ‘got it’ but were also able to ‘apply it’ back in their real world environments!
                                    <br />
                                    <br />
                                    <b>Specialties: </b>Leadership Development (Project management, Agile), Database Development & Analytics, Visual Analytics & Tools
                                </p>
                                trGrace Consulting’s
                                development programs equips
                                individuals with a better
                                understanding of data,
                                analytics, kpi’s, and metrics
                                relevant to their organizations
                                and their reporting
                                requirements. Individuals will
                                also gain more knowledge and
                                hands on labs to develop new
                                skills with tools as well as
                                reinforce existing skills.
                                <p>

                                </p>
                                <Grid container
                                    spacing={3}
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    className='description-grid'>
                                    <Grid item sm={6} className='last-paragraph'>
                                        <p>
                                            Taran’s leadership abilities, strong communication skills and capability to assess a given environment have proven to be a strong asset. She earns the respect of students and clients by creating an environment that has a personal customized touch and by <b><em>demonstrating</em></b> her in-depth knowledge of the subject being presented.
                                        </p>
                                    </Grid>
                                    <Grid item sm={6} className='about-quote'>
                                        <p>
                                            “I assess the requirements of the individual, to find out what the needs are. This allows me to tailor the class/workshop for more specifics as opposed to cookie cutter facilitating. The students and companies respect and appreciate this; it shows that the facilitator has a genuine concern for their diverse needs!”
                                            <br />
                                            --Taran R. Grace
                                        </p>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                    <Grid item className="col" md={6}>
                        {/* <div className='grid-item grid-img'> */}
                        <img src={aboutPic} width="90%" height="auto" alt="Woman leaning against the wall with laptop in hand" />
                        {/* </div> */}
                    </Grid>
                </Grid>
            </section>
            <section className='contact-section'>
                <Link name="contactform" className="title-link" to='#'>
                    <h1 style={{ fontSize: '2em' }}>Contact Us</h1>
                </Link>
                <script src="https://nv695.infusionsoft.app/app/webTracking/getTrackingCode?b=1.70.0.255074-hf-202007131827" type="text/javascript">
                </script>
                <div className="text" id="webformErrors" name="errorContent">
                </div>
                <form acceptCharset="UTF-8" action="https://nv695.infusionsoft.com/app/form/process/9c73d9958b629e934b292553f0ce6ad5" className="infusion-form" id="inf_form_9c73d9958b629e934b292553f0ce6ad5" method="POST" name="Contact Form on site" onSubmit={submitWebForm}>
                    <input name="inf_form_xid" type="hidden" value="9c73d9958b629e934b292553f0ce6ad5" />
                    <input name="inf_form_name" type="hidden" value="Contact Form on site" />
                    <input name="infusionsoft_version" type="hidden" value="1.70.0.258945" />

                    <FormGroup className="default beta-base beta-font-b" id="mainContent" style={{ height: '100%' }}>
                        <Grid container
                            spacing={3}
                            direction="column"
                            justify="center"
                            alignItems="center"
                            className='form-grid'>
                            <Grid item sm={12}>
                                <FormControl>
                                    <InputLabel htmlFor="inf_field_FirstName">First Name *</InputLabel>
                                    <Input className='formControl infusion-field-input' id="inf_field_FirstName" name="inf_field_FirstName" type="text" />
                                </FormControl>
                            </Grid>
                            <Grid item sm={12}>
                                <FormControl>
                                    <InputLabel htmlFor="inf_field_LastName">Last Name *</InputLabel>
                                    <Input className='formControl infusion-field-input' id="inf_field_LastName" name="inf_field_LastName" type="text" />
                                </FormControl>
                            </Grid>
                            <Grid item sm={12}>
                                <FormControl>
                                    <InputLabel htmlFor="inf_field_Email">Email *</InputLabel>
                                    <Input className='formControl infusion-field-input' id="inf_field_Email" name="inf_field_Email" type='email' />
                                </FormControl>
                            </Grid>
                            <Grid item sm={12}>
                                <FormControlLabel
                                    control={<Checkbox checked={state.subscription}
                                        onChange={handleCheckboxChange}
                                        value={state.subscription}
                                        name="subscription"
                                        color="default" />}
                                    label='Okay to receive info about future training?'
                                    id='inf_option_Okaytorecieveinfoaboutfuturetraining'
                                    name="inf_option_Okaytorecieveinfoaboutfuturetraining" />
                            </Grid>
                            <Grid item sm={12}>
                                <FormControl>
                                    <InputLabel id='training-type-label' for="inf_custom_ClassType">Type *</InputLabel>
                                    <Select
                                        labelId='training-type-label'
                                        id="inf_custom_ClassType"
                                        name="inf_custom_ClassType"
                                        className='select-control formControl'
                                        value={state.inf_custom_ClassType}
                                        onChange={handleSelectChange}>
                                        <MenuItem value='Personal'>Personal</MenuItem>
                                        <MenuItem value='Business'>Business</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item sm={12}>
                                <FormControl>
                                    <InputLabel id='class-label' for="inf_custom_Training">Class *</InputLabel>
                                    <Select
                                        labelId='class-label'
                                        id="inf_custom_Training"
                                        name="inf_custom_Training"
                                        className='select-control formControl'
                                        value={state.inf_custom_Training}
                                        onChange={handleSelectChange}>
                                        <MenuItem value='querying'>Data Analytics & Querying</MenuItem>
                                        <MenuItem value='visual-analytic'>Visual Analytic Tools</MenuItem>
                                        <MenuItem value='project-management'>Project Management</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <div>
                                <div style={{ height: '15px', lineHeight: '15px' }}>
                                    &nbsp;
                                </div>
                            </div>
                            <Grid item sm={12}>
                                <div className="infusion-submit" style={{ textAlign: 'center' }}>
                                    <Button type="submit" value="Submit" variant='contained' className='submit-btn infusion-recaptcha' id="recaptcha_9c73d9958b629e934b292553f0ce6ad5">Submit</Button>
                                </div>
                                <br />
                            </Grid>
                        </Grid>
                    </FormGroup >
                </form >
                <br />
                <script type="text/javascript" src="https://nv695.infusionsoft.app/app/webTracking/getTrackingCode"></script>
                <script type="text/javascript" src="https://nv695.infusionsoft.com/resources/external/recaptcha/production/recaptcha.js?b=1.70.0.258945-hf-202007272136"></script>
                <script src="https://www.google.com/recaptcha/api.js?onload=onloadInfusionRecaptchaCallback&render=explicit" async="async" defer="defer"></script>
                <script type="text/javascript" src="https://nv695.infusionsoft.com/app/timezone/timezoneInputJs?xid=9c73d9958b629e934b292553f0ce6ad5"></script>
            </section >
        </>
    )
}

export default About

import React from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import './Home.css';

function Home(props) {
    return (
        <div>
            {/* Learn about products and services */}
            <section className='prodServ-area'>
                {/* jump to products/services form section */}
                <Button varient='contained' className='btn'><Link to='/services' className='btn-link'>Training & Development</Link></Button>
                <Button varient='contained' className='btn'><Link to='/services' className='btn-link'>Consulting</Link></Button>
            </section>
        </div>
    )
}

export default Home;

import React, { Component } from 'react';
import { IconButton } from '@material-ui/core';
import { ArrowUpwardOutlined } from '@material-ui/icons';

export class ScrollToTopButton extends Component {
    constructor(props) {
        super(props);
        //set button visibility
        this.state = {
            isVisible: false
        };
    }

    componentDidMount() {
        //when component scrolls, toggle the visibility
        var scrollComponent = this;
        document.addEventListener("scroll", (evt) => {
            scrollComponent.toggleVisibility();
        })
    }

    toggleVisibility() {
        //checks scroll position and updates visibility
        if (window.pageYOffset > 300) {
            this.setState({
                isVisible: true
            });
        }
        else {
            this.setState({
                isVisible: false
            });
        }

    }

    scrollToTop = () => {
        //scrolls to top of page
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    render() {
        const { isVisible } = this.state;
        return (
            <div className="scroll-to-top">
                {isVisible && (
                    <IconButton className='scroll-btn' variant='contained' aria-label="scroll to top"
                                onClick={this.scrollToTop}>
                        <ArrowUpwardOutlined />
                        <p>Top</p>
                    </IconButton>
                )}
            </div>
        )
    }
}

export default ScrollToTopButton

import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import './Development.css';

function Development() {
    return (
        <>
            <p className='dev-txt'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris in magna iaculis, maximus metus et, aliquam nibh. Suspendisse mollis metus sit amet dictum cursus. Nam tellus metus, finibus a gravida mollis, feugiat nec enim. Morbi sit amet massa aliquam tellus egestas molestie. Sed ac gravida arcu. Ut a dapibus tortor. Maecenas diam turpis, volutpat eget sapien et, luctus blandit metus. Aliquam non orci vitae orci fringilla ultricies. Cras in felis vel quam congue interdum mattis et ante. Etiam tincidunt eros ac elit maximus gravida. Nullam dui nisl, facilisis non lobortis ut, placerat ac sapien. Donec sed quam lacinia, facilisis massa quis, lobortis mi. Donec aliquam, nisl et sollicitudin dictum, est risus dignissim felis, sed porta risus elit ut diam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
                Vestibulum vel venenatis diam. Quisque consequat, turpis quis posuere gravida, ligula tellus ullamcorper augue, sit amet pharetra quam diam eu odio. Fusce nec enim sit amet metus commodo convallis. In mattis nunc dui, vitae laoreet nulla elementum sed. Maecenas id commodo dui, vitae auctor nunc. Morbi semper sem eget nibh convallis vestibulum. Sed dignissim luctus nunc, vel tempor enim lobortis sagittis. Ut laoreet dui nec quam blandit, vitae dictum libero tincidunt.
                Integer arcu turpis, euismod ut suscipit nec, pharetra sit amet ex. Etiam vel interdum neque, in commodo turpis. Fusce vulputate vel dui at condimentum. Nunc nec massa ut turpis luctus auctor. Nullam sit amet nisl id elit euismod gravida. Aliquam in posuere leo. Integer aliquet malesuada turpis, vel scelerisque lacus tristique non. Nunc leo turpis, porttitor a purus eget, scelerisque tempus neque. Pellentesque in lorem et purus porttitor molestie vitae sit amet mauris. Quisque non felis iaculis, ultrices lorem non, pellentesque ligula. Donec non tellus sit amet turpis auctor viverra sit amet nec odio. Ut in rutrum ante. Vivamus eleifend ligula vitae neque faucibus molestie.
                Nunc vel dolor ac metus pretium tempor. Ut dictum eros enim, ac consequat urna tempor eu. Aliquam sit amet malesuada diam. Morbi eget orci magna. In ornare at dolor vel imperdiet. In euismod malesuada nunc, quis porttitor sem fringilla eget. In tortor dolor, porta vel risus sit amet, posuere blandit tortor. Pellentesque ac velit viverra, finibus erat vel, blandit augue. Nulla facilisi. Morbi ac interdum erat. Sed efficitur ac arcu sit amet convallis.
                Cras ut semper enim. Maecenas mattis justo ut urna lobortis pulvinar sit amet vel ipsum. Nullam eleifend vitae lacus ut commodo. Quisque sit amet libero nulla. Donec eget luctus nibh, egestas malesuada lacus. Morbi et nisl sagittis, tempus metus quis, sollicitudin elit. Pellentesque convallis felis vitae libero malesuada fermentum.
            </p>
            <section className='classes'>
                <Grid container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    className='dev-classes'>
                    <Grid item sm={3}>
                        <Grid container justify={"center"}>
                            <Button varient='contained' className='btn'>
                                <Link to='/services/development/project-management' className='btn-link'>Project <br /> Management</Link>
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item sm={3}>
                        <Grid container justify={"center"}>
                            <Button varient='contained' className='btn'>
                                <Link to='/services/development/data-analytics-and-querying' className='btn-link'>Microsoft <br /> Data Analytics & Querying</Link>
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item sm={3}>
                        <Grid container justify={"center"}>
                            <Button varient='contained' className='btn'>
                                <Link to='/services/development/visual-analytic-tools' className='btn-link'>Visual <br /> Analytic <br /> Tools</Link>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </section>
        </>
    )
}

export default Development

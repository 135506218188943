import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import SearchBar from 'material-ui-search-bar';
import bw from '../../media/bwSpeech.jpg';
import am from '../../media/amSpeech.jpg';
import clap from '../../media/womanClapping.jpg';
import logo from '../../media/logo.png';
import './MainContainer.css';
import {
    Grid
} from '@material-ui/core';

function MainContainer(props) {
    const { introBox, media, isClass, isHome, photo, alt, id } = props;

    useEffect(() => {
        var slideshow = (function () {
            var count = 0,
                i,
                j,
                slides = $("#slideshow .slide"),
                slidesLength = slides.length - 1;
            for (i = 0, j = 9999; i < slides.length; i += 1, j -= 1) {
                $(slides[i]).css("z-index", j);
            }
            return {
                startSlideshow: function () {
                    window.setInterval(function () {
                        if (count === 0) {
                            slides.eq(count).fadeOut();
                            count += 1;
                        } else if (count === slidesLength) {
                            count = 0;
                            slides.eq(count).fadeIn(function () {
                                slides.fadeIn();
                            });
                        } else {
                            slides.eq(count).fadeOut();
                            count += 1;
                        }
                    }, 5000);
                }
            };
        }());

        slideshow.startSlideshow();
    }, []);

    return (
        <>
            {/* Intro  */}
            <section className='intro-wrapper'>
                <div className='welcome-wrapper'>
                    <div className='welcome-box'>
                        <div className="welcome">
                            <Grid justify={"space-between"} container>
                                <Grid xs={2} item>
                                    <Link to='/'><img src={logo} width="70%" height="auto" className='logo' alt="T R Grace Consulting Logo with black background" /></Link>
                                </Grid>
                                <Grid xs={10} item>
                                    {isHome ?
                                        <h1 className='title'>trGrace Consulting</h1>
                                        : <h1>trGrace Consulting</h1>
                                    }
                                    <br />
                                </Grid>
                            </Grid>
                            {introBox}
                            <SearchBar className='home-search searchbar' />
                        </div>
                    </div>
                    {isClass ?
                        <div className='photo-box' id={id}>
                            <img src={photo} alt={alt} />
                        </div> : <></>
                        // <div className='media-box'>
                        //     <div className="media-content">
                        //         {media}
                        //         {/* <span>media content will go here</span> */}
                        //     </div>
                        // </div>
                    }
                </div>
                <div id="slideshow">
                    <img className='slide' src={bw} alt="Woman speaking to audience" />
                    <img className='slide' src={clap} alt="audience members smiling and clapping" />
                    <img className='slide' src={am} alt="Man, standing next to board, presenting to group" />
                </div>
                <div className='overlay'>

                </div>
            </section>
        </>
    )
}

export default MainContainer

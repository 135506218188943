import React, { useState } from 'react';
import {
    Button, Grid, Link
} from '@material-ui/core';
import ModalRegister from '../../Constants/Modal/ModalRegister';
import CourseTable from '../../Constants/CourseTable';
import group from '../../media/group.png';
import faq from '../../media/faq.png';
import graphic from '../../media/development-graphic-soon.png';
import './Classes.css';

function Classes(props) {
    const { classData, isAvail, details, noTable, noDetails, isVis, altDesign, pathh } = props;
    const data = classData ? classData.filter(c => c.acf.class_type === details.category) : '';
    const classCategory = details.endpoint;
    const [modalOpen, setModalOpen] = useState(false);
    const values = Object.values(details.overview);

    let handleModalOpen = () => {
        setModalOpen(true);
    };

    let handleModalClose = () => {
        setModalOpen(false);
    };

    return (
        <>
            {isVis ?
                <div className='class-title'>
                    <h2>Business Intelligence Data Analytics & Visualization Tools</h2>
                </div>

                : <></>

            }
            {(isAvail) ?
                (!altDesign) ?
                    noTable ? <></> :
                        <>
                            <h2 className='table-title title-classes'>Upcoming Dates</h2>
                            <div className='table-container'>
                                <CourseTable data={data} handleModalOpen={handleModalOpen} />
                            </div>
                        </>
                    : <></>
                :
                <div className='coming'>
                    <h2>Coming Soon</h2>
                </div>
            }
            {
                noTable ? <></> :
                    <ModalRegister open={modalOpen} setOpen={setModalOpen}
                        handleClose={handleModalClose} classData={data}
                        modalTitle='registration-form' modalDescription='This is a pop up form for clients to register for a course'
                        category={classCategory} />
            }
            {noDetails ? <></> :

                <Grid container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    className='class-grid'>
                    <Grid item sm={8} className='item-txt'>
                        {/* <h2 className='overview-title title-classes'>Overview</h2> */}
                        {/* <div className='txt-classes' id="overview">
                        {values.map((para, index) => (
                            <p key={index}>{para}</p>
                        ))}
                    </div> */}
                        {/* <h2 className='agenda-title'>Agenda</h2> */}
                        <div className='txt-classes'>
                            {
                                details.agenda.sessions.map((session, index) => (
                                    <React.Fragment key={index}>
                                        <h5>{session.sessionTitle}</h5>
                                        <p>{session.description}</p>
                                        <ul>
                                            {session.items.map((item, index) => (
                                                <>
                                                    <li key={index}>{Object.keys(item)}</li>
                                                    {Object.values(item) ?
                                                        Object.values(item).map((subCat, i) => (
                                                            subCat.map((subItem, id) => (
                                                                <ul>
                                                                    <li key={i}>{subItem}</li>
                                                                </ul>
                                                            ))
                                                        )) :
                                                        <></>}
                                                </>
                                            ))}
                                        </ul>
                                    </React.Fragment>
                                ))
                            }
                        </div>
                    </Grid>
                    <Grid item sm={4} className='strip-item'>
                        <div className='link-strip'>
                            <div className='rates group'>
                                <img src={group} alt="Group of 3 people icon" />
                                <p>Special group rates available</p>
                            </div>
                            <hr />
                            <div className='faq group'>
                                <img src={faq} alt="question mark icon" />
                                <br />
                                <br />
                                <a href="mailto:techsupport@trgraceconsulting.com" className='faq-link'>Contact Support</a>
                            </div>
                            <hr />
                            {
                                noTable ? <></> :
                                    <div className='register group'>
                                        <Button
                                            varient='contained'
                                            className='register-btn'
                                            id='register'
                                            onClick={handleModalOpen}>
                                            Learn More
                                        </Button>
                                    </div>
                            }
                        </div>
                    </Grid>
                </Grid >
            }
            {altDesign ?

                <Grid container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    className='class-grid'>
                    <img src={graphic} className='class-grahic' />
                    <Grid item sm={4} className='strip-item'>
                        <div className='link-strip'>
                            <h3>Categories</h3>
                            <div className='cat-group'>
                                <ul>
                                    {
                                        details.agenda.category.map((cat, index) => (
                                            <a href={`.${pathh}#${cat.linkName}`}>
                                                <li key={index}>
                                                    {cat.title}
                                                </li>
                                            </a>
                                        ))
                                    }
                                </ul>
                            </div>
                            <hr />
                            <div className='rates group'>
                                {/* <img src={group} alt="Group of 3 people icon" /> */}
                                <p>Special group rates available</p>
                            </div>
                            <hr />
                            <div className='faq group'>
                                <img src={faq} alt="question mark icon" />
                                <br />
                                <br />
                                <a href="mailto:techsupport@trgraceconsulting.com" className='faq-link'>Contact Support</a>
                            </div>
                            <hr />
                            {
                                noTable ? <></> :
                                    <div className='register group'>
                                        <Button
                                            varient='contained'
                                            className='register-btn'
                                            id='register'
                                            onClick={handleModalOpen}>
                                            Learn More
                                        </Button>
                                    </div>
                            }
                        </div>
                    </Grid>
                    <Grid item sm={8} className='item-txt'>
                        {/* <h2 className='overview-title title-classes'>Overview</h2> */}
                        {/* <div className='txt-classes' id="overview">
                        {values.map((para, index) => (
                            <p key={index}>{para}</p>
                        ))}
                    </div> */}
                        {/* <h2 className='agenda-title'>Agenda</h2> */}
                        <div className='txt-classes'>
                            {
                                details.agenda.category.map((cat, index) => (
                                    <React.Fragment key={index}>
                                        <a name={cat.linkName}>
                                            <h5>
                                                {cat.title}
                                            </h5>
                                        </a>
                                        <p>{cat.description}</p>
                                        {cat.linkName == "upcoming" ?
                                            (isAvail) ?
                                                noTable ? <></> :
                                                    <>
                                                        <div className='table-container'>
                                                            <CourseTable data={data} handleModalOpen={handleModalOpen} />
                                                        </div>
                                                    </>
                                                :
                                                <div className='coming'>
                                                    <h2>Coming Soon</h2>
                                                </div>
                                            : <></>
                                        }
                                    </React.Fragment>
                                ))
                            }
                        </div>
                    </Grid>
                </Grid >
                : <></>
            }
        </>
    )
}

export default Classes

import React from 'react';
import './Home.css';

function HomeContent(props) {
    return (
        <>
            {/* <h4 className='subtitle'>subitle/tagline goes here</h4> */}
        </>
    )
}

export default HomeContent

import React, { useState } from 'react';
import {
    Modal, Fade, Grid, FormControl,
    InputLabel, Input, Button, Select,
    MenuItem, Backdrop, TextField
} from '@material-ui/core';
import './ModalForm.css';

function ModalOnline(props) {
    //Form pops up on webinars and virtual seminars pages
    const [className, setClassName] = useState('');

    const handleSelectChange = (event) => {
        setClassName(event.target.value);
    }

    let scrollToTop = () => {
        //scrolls to top of page
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    const { modalTitle, modalDescription, open,
        handleClose, classData, category, courseType } = props;

    return (
        <div>
            <Modal
                aria-labelledby={modalTitle}
                aria-describedby={modalDescription}
                className='modal-form'
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}>
                <Fade in={open}>
                    <form acceptCharset="UTF-8" action="https://nv695.infusionsoft.com/app/form/process/8afe11a5ce0230dc360bdda9202651a1" className="infusion-form" id="inf_form_8afe11a5ce0230dc360bdda9202651a1" method="POST">
                        <input name="inf_form_xid" type="hidden" value="8afe11a5ce0230dc360bdda9202651a1" />
                        <input name="inf_form_name" type="hidden" value="Web Form submitted" />
                        <input name="infusionsoft_version" type="hidden" value="1.70.0.291307" />
                        <Grid container
                            spacing={3}
                            className='modal-grid'>
                            <Grid container md={6}
                                direction="column"
                                justify="center"
                                alignItems="center">
                                <Grid item sm={12}>
                                    <FormControl>
                                        <InputLabel htmlFor="inf_field_FirstName">First Name *</InputLabel>
                                        <Input className='formControl infusion-field-input' id="inf_field_FirstName" name="inf_field_FirstName" type="text" />
                                    </FormControl>
                                </Grid>
                                <Grid item sm={12}>
                                    <FormControl>
                                        <InputLabel htmlFor="inf_field_LastName">Last Name *</InputLabel>
                                        <Input className='formControl infusion-field-input' id="inf_field_LastName" name="inf_field_LastName" type="text" />
                                    </FormControl>
                                </Grid>
                                <Grid item sm={12}>
                                    <FormControl>
                                        <InputLabel htmlFor="inf_field_Email">Email *</InputLabel>
                                        <Input className='formControl infusion-field-input' id="inf_field_Email" name="inf_field_Email" type="text" />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container md={6}
                                direction="column"
                                justify="center"
                                alignItems="center">
                                <Grid item sm={12}>
                                    <FormControl>
                                        <InputLabel htmlFor="inf_field_Phone1">Phone *</InputLabel>
                                        <Input className="infusion-field-input" id="inf_field_Phone1" name="inf_field_Phone1" type="text" />
                                    </FormControl>
                                </Grid>
                                <Grid item sm={12}>
                                    <FormControl>
                                        <InputLabel htmlFor="inf_field_Company">Company</InputLabel>
                                        <Input className="infusion-field-input" id="inf_field_Company" name="inf_field_Company" type="text" />
                                    </FormControl>
                                </Grid>
                                <Grid item sm={12}>
                                    <FormControl>
                                        <TextField
                                            id="inf_custom_Notes"
                                            name="inf_custom_Notes"
                                            label="Notes"
                                            multiline
                                            rows={1}
                                            placeholder="Notes"
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container md={12}
                                direction="column"
                                justify="center"
                                alignItems="center">
                                <Grid item sm={12}>
                                    <FormControl>
                                        <InputLabel id='className-label' htmlFor="inf_custom_ClassName">Class *</InputLabel>
                                        <Select
                                            labelId='className-label'
                                            className='select-control formControl'
                                            id="inf_custom_ClassName"
                                            name="inf_custom_ClassName"
                                            value={className}
                                            onChange={handleSelectChange}>
                                            {classData.map((option) => (
                                                <MenuItem key={option.id} value={option.title.rendered}
                                                    data-id={option.id} className='register-item'
                                                    id={`classItem-[${option.value}]`}>{option.title.rendered} | {option.acf.class_date}</MenuItem>
                                            ))}
                                            <MenuItem value={"info"} className='register-item'>More Info</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {/* <FormControl style={{ display: 'none' }}>
                                        <InputLabel htmlFor="inf_custom_ClassID">Class ID</InputLabel>
                                        <Input className="infusion-field-input" id="inf_custom_ClassID"
                                            name="inf_custom_ClassID" type="text"
                                            value={classID || ''}
                                        />
                                    </FormControl> */}
                                    <FormControl style={{ display: 'none' }}>
                                        <InputLabel id='classCat-label' htmlFor="inf_custom_ClassCategory">Class Category</InputLabel>
                                        <Select
                                            labelId='classCat-label'
                                            className='select-control formControl'
                                            id="inf_custom_ClassCategory"
                                            name="inf_custom_ClassCategory"
                                            value={category}
                                        >
                                            <MenuItem value={"project-management"} className='register-item'>project</MenuItem>
                                            <MenuItem value={"data-analytics-and-querying"} className='register-item'>querying</MenuItem>
                                            <MenuItem value={"visual-analytic-tools"} className='register-item'>Visual</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl style={{ display: 'none' }}>
                                        <InputLabel id='courseType-label' htmlFor="inf_custom_CourseType">Course Type</InputLabel>
                                        <Select
                                            labelId='courseType-label'
                                            className='select-control formControl'
                                            id="inf_custom_CourseType"
                                            name="inf_custom_CourseType"
                                            value={courseType}
                                        >
                                            <MenuItem value={"Webinar"} className='register-item'>webinar</MenuItem>
                                            <MenuItem value={"Virtual Seminar"} className='register-item'>seminar</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <br />
                        <div className="infusion-submit" style={{ textAlign: 'center' }}>
                            <Button type="submit" variant='contained' className='submit-btn' onClick={scrollToTop}>Submit</Button>
                        </div>
                        <br />
                    </form>
                </Fade>
            </Modal>
            <script type="text/javascript" src="https://nv695.infusionsoft.app/app/webTracking/getTrackingCode"></script>
            <script type="text/javascript" src="https://nv695.infusionsoft.com/app/timezone/timezoneInputJs?xid=a5229eae8900b6c2d5de2666197f8430"></script>
        </div>
    )
}

export default ModalOnline

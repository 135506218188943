import React from 'react'

function AppFooter() {
    return (
        <>
            <footer>
                
            </footer>
        </>
    )
}

export default AppFooter

import React from 'react';
import { Table, TableRow, TableCell, TableBody, Button } from '@material-ui/core';
import '../App.css';

function CourseTable(props) {
    const { data, handleModalOpen, isOnline } = props;
    let discountFlag = false;

    //checks to see if there is an early bird price that is current
    let isEarlyBird = (field, expiry) => {
        let d = new Date();
        if (field && (d <= new Date(`${expiry}`))) {
            discountFlag = true;
        }
    }

    return (
        <>
            <Table className='table' aria-label="table of available courses">
                <TableBody>
                    {data.map((row) => (
                        <>
                            {/* for each row, check for the presence of an early bird price */}
                            {isEarlyBird(row.acf.early_bird_price, row.acf.early_bird_date)}
                            {/* inputting html elements based on whether it is online (webinar or seminar) or not online (course). */}
                            <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                    <strong className='class-date'>{`${row.acf.class_date} | `}</strong>
                                    <span className='class-time'>{`${row.acf.class_time}`}</span>
                                </TableCell>
                                {isOnline ?
                                    <TableCell component="th" scope="row">
                                        <span className={discountFlag ? 'class-price full-price' : 'class-price'}>{`$${row.acf.class_price}`}</span>
                                        {discountFlag ?
                                            <>
                                                <span className="class-price early-bird">{`$${row.acf.early_bird_price}`}</span>
                                                <span className='early-bird-date'>{`Early Bird Special until ${row.acf.early_bird_date.slice(0, -5)}`}</span>
                                            </> : <></>}
                                    </TableCell>
                                    :
                                    <TableCell align="left">
                                        <span className={discountFlag ? 'class-price full-price' : 'class-price'}>{`$${row.acf.class_price}`}</span>
                                    </TableCell>
                                }
                                <TableCell align="center">
                                    <Button variant='contained' className='register-btn'
                                        onClick={handleModalOpen}>Learn More</Button>
                                </TableCell>
                                <TableCell align="center">
                                    <p className="open-soon">OPEN SOON</p>
                                    <Button variant='disabled' className='register-btn'
                                        onClick={handleModalOpen}>Register</Button>
                                </TableCell>
                            </TableRow>
                        </>
                    ))}
                </TableBody>
            </Table>
        </>
    )
}

export default CourseTable

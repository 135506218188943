import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

//components
import Navigation from './Constants/Navigation/Navigation';
import Home from './Views/Home/Home';
import HomeContent from './Views/Home/HomeContent';
import DevContent from './Views/Services/Development/DevContent';
import About from './Views/About/About';
import ImportantLinks from './Constants/ImportantLinks';
import AppFooter from './Constants/AppFooter';
import Services from './Views/Services/Services';
import Development from './Views/Services/Development/Development';
import MainContainer from './Views/MainContainer/MainContainer';
import AboutSnippet from './Constants/AboutSnippet';
import Classes from './Views/Classes/Classes';
import OnlineResources from './Views/OnlineResources/OnlineResources';

//utilities
import ScrollToTop from './Constants/ScrollToTop';
// import data from './Constants/Data/data.json';
import classDetails from './Constants/Data/description.json';
import ScrollToTopButton from './Constants/ScrollToTopButton';

//media and styles
import aboutPic from './media/about-img.jpg';
import projPhoto from './media/personalDev.jpg';
import excelPhoto from './media/excel.jpg';
import visualPhoto from './media/visualTools.jpg';
import virtualSeminarPhoto from './media/VirtualSeminar.png';
import './App.css';

function classContent(classType) {
  switch (classType) {
    case 'project':
      return (
        <>
          <h1 className='title'>Project Management</h1>
          {/* <h4 className='subtitle'>subitle/tagline goes here</h4> */}
        </>
      )
    case 'querying':
      return (
        <>
          <h1 className='title'>Data Analytics & Querying</h1>
          {/* <h4 className='subtitle'>subitle/tagline goes here</h4> */}
        </>
      )
    case 'visual':
      return (
        <>
          <h1 className='title'>Visual Analytic Tools</h1>
          {/* <h4 className='subtitle'>subitle/tagline goes here</h4> */}
        </>
      )
    case 'seminar':
      return (
        <>
          <h1 className='title'>Live Virtual Seminars</h1>
          <h4 className='subtitle'>A dynamic and innovative learning method combining a virtual live experience, with real-
          world hands-on training scenarios and labs.</h4>
        </>
      )
    case 'webinar':
      return (
        <>
          <h1 className='title'>Webinars</h1>
          {/* <h4 className='subtitle'>subitle/tagline goes here</h4> */}
        </>
      )

    default:
      break;
  }
}

function App() {
  const [classData, setClassData] = useState([]);
  const [seminarData, setSeminarData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const classResponse = await axios.get('https://trgraceconsulting.com/api/wp-json/wp/v2/classes');
      const seminarResponse = await axios.get('https://trgraceconsulting.com/api/wp-json/wp/v2/seminars');
      setClassData(classResponse.data);
      setSeminarData(seminarResponse.data);
    }
    fetchData();
  }, []);

  return (
    <div className='App'>
      <Router>
        <ScrollToTop />
        <Navigation />
        <Switch>
          {/* HOME page */}
          <Route exact path='/'>
            <MainContainer className='template' introBox={<HomeContent />} isClass={false} isHome/>
            <AboutSnippet aboutPic={aboutPic} />
            <Home className='homeComponent' />
            <ScrollToTopButton />
          </Route>
          {/* ABOUT page */}
          <Route exact path='/about'>
            <About aboutPic={aboutPic} />
          </Route>
          {/* VIRTUAL SEMINARS page */}
          <Route exact path='/virtual-seminars'>
            <MainContainer className='template' introBox={classContent('seminar')} isClass photo={virtualSeminarPhoto} id={"virtual-seminar-photo"} />
            <OnlineResources courseType={'Virtual Seminar'} isAvail data={seminarData} />
            <Classes noTable isAvail={true} details={classDetails.project} details={classDetails.seminar} />
          </Route>
          {/* WEBINARS page */}
          {/* <Route exact path='/webinars'>
            <MainContainer className='template' introBox={classContent('webinar')} isClass={false} />
            <OnlineResources courseType={'Webinar'} isAvail={false} data={[]} />
          </Route> */}
          {/* SERVICES page (learning and development) */}
          <Route exact path='/services'>
            <Services />
          </Route>
          {/* DEVELOPMENT page (currently not in use) */}
          {/* <Route exact path='/services/development'>
            <MainContainer className='template' introBox={<DevContent isClass={false} />} />
            <AboutSnippet aboutPic={aboutPic} />
            <Development />
          </Route> */}
          {/* PROJECT MANAGEMENT page (hidden page and information) */}
          <Route exact path='/services/development/project-management'>
            <MainContainer className='template' introBox={classContent('project')} isClass={true} photo={projPhoto} alt={"teammates helping each other with computer code"} />
            <Classes /*data={data.classes.personal}*/ classData={classData} isAvail={false} details={classDetails.project} noDetails />
          </Route>
          {/* DATA ANALYTICS & QUERYING page */}
          <Route exact path='/services/development/data-analytics-and-querying'>
            <MainContainer className='template' introBox={classContent('querying')} isClass={true} photo={excelPhoto} alt={"laptop, sitting on a wooden desk, with a spreadsheet open"} />
            <Classes classData={classData} classType={'querying'} isAvail={true} details={classDetails.querying} noDetails altDesign pathh={'/services/development/data-analytics-and-querying'}/>
          </Route>
          {/* VISUAL ANALYTIC TOOLS page */}
          <Route exact path='/services/development/visual-analytic-tools'>
            <MainContainer className='template' introBox={classContent('visual')} isClass={true} photo={visualPhoto} alt={"Visual graphs on laptop"} />
            <Classes classData={classData} isAvail={true} details={classDetails.visual} isVis={true} pathh={'/services/development/visual-analytic-tools'}/>
          </Route>
          {/* Checkout page */}
          <Route exact path='/checkout'>
            
          </Route>
        </Switch>
        <ScrollToTopButton />
        <ImportantLinks />
        <AppFooter />
      </Router>
    </div>
  );
}

export default App;

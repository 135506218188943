import React from 'react';
import './Development.css';

function DevContent(props) {
    return (
        <>
            <h1 className='title'>Development</h1>
            <h4 className='subtitle'>subitle/tagline goes here</h4>
        </>
    )
}

export default DevContent

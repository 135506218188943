import React, { useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ModalFormConsulting from '../../Constants/Modal/ModalFormConsulting';
import ModalFormDev from '../../Constants/Modal/ModalFormDev';
import './Services.css';

function Services() {
    const [consultOpen, setConsultOpen] = useState(false);
    const [devOpen, setDevOpen] = useState(false);

    let handleConsultOpen = () => {
        setConsultOpen(true);
    };

    let handleConsultClose = () => {
        setConsultOpen(false);
    };

    let handleDevOpen = () => {
        setDevOpen(true);
    };

    let handleDevClose = () => {
        setDevOpen(false);
    };

    return (
        <>
            <h1 id="services-page">Services</h1>
            <section className='serv-section'>
                <Grid container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    className='serv-grid'>
                    <Grid item md={6}>
                        <div className='grid-item'>
                            <h2>Development</h2>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                Nam accumsan, augue ac sagittis consectetur, turpis purus
                                vulputate nibh, a commodo purus augue vitae quam. Praesent
                                dictum lobortis ornare. Mauris gravida dictum congue.
                                Suspendisse dictum pretium mi in ultricies. Nullam bibendum,
                                justo nec eleifend faucibus, dolor lacus placerat libero, ac
                                sagittis odio mauris eu ligula. Curabitur laoreet commodo diam
                                a placerat. Proin facilisis fermentum nibh quis fermentum. Etiam
                                ac enim eget purus vehicula laoreet.

                                Curabitur feugiat sollicitudin ultrices. Sed maximus nisl placerat
                                nisl fermentum, vel vestibulum est pellentesque. Praesent consectetur,
                                libero sed blandit rhoncus, tortor ligula iaculis nisi, posuere
                                varius urna dui vitae massa. Class aptent taciti sociosqu ad litora
                                torquent per conubia nostra, per inceptos himenaeos. Morbi feugiat
                                sapien at felis vulputate lacinia. Pellentesque bibendum lobortis dui,
                                sit amet viverra turpis venenatis sit amet. Curabitur faucibus nunc
                                vitae diam pulvinar viverra. Praesent tempus dolor a libero hendrerit
                                ornare. Etiam vulputate accumsan lobortis. Etiam molestie nisl ac
                                condimentum faucibus. Phasellus sit amet consequat leo. Phasellus
                                dictum ligula tortor, quis rutrum libero facilisis vitae. Mauris cursus
                                lorem ut faucibus aliquet. In hac habitasse platea dictumst.
                            </p>
                            <div className='prod-btn'>
                                <Button variant="contained" className='btn' onClick={handleDevOpen}>LEARN MORE</Button>
                            </div>
                        </div>
                    </Grid>
                    <Grid item md={6}>
                        <div className='grid-item'>
                            <h2>Consulting</h2>
                            <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                Nam accumsan, augue ac sagittis consectetur, turpis purus
                                vulputate nibh, a commodo purus augue vitae quam. Praesent
                                dictum lobortis ornare. Mauris gravida dictum congue.
                                Suspendisse dictum pretium mi in ultricies. Nullam bibendum,
                                justo nec eleifend faucibus, dolor lacus placerat libero, ac
                                sagittis odio mauris eu ligula. Curabitur laoreet commodo diam
                                a placerat. Proin facilisis fermentum nibh quis fermentum. Etiam
                                ac enim eget purus vehicula laoreet.

                                Curabitur feugiat sollicitudin ultrices. Sed maximus nisl placerat
                                nisl fermentum, vel vestibulum est pellentesque. Praesent consectetur,
                                libero sed blandit rhoncus, tortor ligula iaculis nisi, posuere
                                varius urna dui vitae massa. Class aptent taciti sociosqu ad litora
                                torquent per conubia nostra, per inceptos himenaeos. Morbi feugiat
                                sapien at felis vulputate lacinia. Pellentesque bibendum lobortis dui,
                                sit amet viverra turpis venenatis sit amet. Curabitur faucibus nunc
                                vitae diam pulvinar viverra. Praesent tempus dolor a libero hendrerit
                                ornare. Etiam vulputate accumsan lobortis. Etiam molestie nisl ac
                                condimentum faucibus. Phasellus sit amet consequat leo. Phasellus
                                dictum ligula tortor, quis rutrum libero facilisis vitae. Mauris cursus
                                lorem ut faucibus aliquet. In hac habitasse platea dictumst.
                            </p>
                            <div className='prod-btn'>
                                <Button variant="contained" className='btn' defaultValue='consulting' onClick={handleConsultOpen}>LEARN MORE</Button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <ModalFormConsulting open={consultOpen} setOpen={setConsultOpen} handleClose={handleConsultClose} modalTitle='interest-form' modalDescription='This is a pop up form for clients that are interested in a consultation' />
                <ModalFormDev open={devOpen} setOpen={setDevOpen} handleClose={handleDevClose} modalTitle='interest-form' modalDescription='This is a pop up form for clients that are interested in a development service' />
            </section>
            <br />
            <br />
        </>
    )
}

export default Services
